<template>
  <b-row>
    <b-col cols="12" lg="6">
      <b-card no-header>
        <template slot="header">
          Application Settings
        </template>
        <b-form-group label="Base URL" label-for="baseUrl">
          <b-input-group>
            <b-form-input id="baseUrl" type="text" v-model="application.baseUrl"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Base API URL" label-for="baseApiUrl">
          <b-input-group>
            <b-form-input id="baseApiUrl" type="text" v-model="application.baseApiUrl"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Notification Contact" label-for="notificationContactId">
          <b-input-group>
            <b-form-input id="notificationContactId" type="text" v-model="application.notificationContactId"></b-form-input>
          </b-input-group>
        </b-form-group>
        <template slot="footer">
          <b-row>
            <b-col sm="12" class="text-right">
              <b-button variant="success" @click="save">Save</b-button>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'ApplicationSettings',
  data: () => {
    return {
      application: {
        baseUrl: null,
        teamEmail: null
      }
    }
  },
  methods: {
    fetchConfig () {
      this.$api.configurations.get('application')
        .then((response) => {
          let config = response.data.application || {}
          this.application.baseUrl = config.baseUrl || null
          this.application.baseApiUrl = config.baseApiUrl || null
          this.application.notificationContactId = config.notificationContactId || null
        })
        .catch((err) => {
          this.$snotify.error('Error fetching configuration')
        })
    },
    save () {
      this.$api.configurations.bulkUpdate({ application: { ...this.application } })
        .then((response) => {
          this.$snotify.success('Configuration Saved')
        })
        .catch((err) => {
          this.$snotify.error('Error Saving Configuration')
        })
    }
  },
  created () {
    this.fetchConfig()
  }
}
</script>
